import { defineComponent, toRefs, reactive, computed, watch, nextTick, defineAsyncComponent } from "vue";
import Eldialog from "@/components/Eldialog/index.vue";
import API from "@/api/authority/index";
import { useMessage } from '@/hooks/web/useMessage';
import Emitter from "@/eventBus/index";
import { useDebounce } from '@/hooks/core/useDebounce';
export default defineComponent({
  name: "AddOrganization",
  emits: ["handleSuccess", "update:visible"],
  components: {
    Eldialog,
    OrganizeSelect: defineAsyncComponent(() => import("@/components/OrganizeSelect/index.vue")),
    AreaSelect: defineAsyncComponent(() => import("@/components/AreaSelect/index.vue")),
    TypeSelect: defineAsyncComponent(() => import("@/components/TypeSelect/TypeSelect.vue")),
    Resource: defineAsyncComponent(() => import("./Resource.vue"))
  },
  props: {
    activeOrg: {
      type: Object,
      default: {
        orgPid: null,
        orgName: ''
      }
    },
    orgId: {
      type: Number,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const refData = reactive({
      roleOrgId: 1,
      roleType: [],
      addType: 'org',
      resourceVisible: false,
      resourceRef: null,
      selectAreaValue: [],
      searchValue: "",
      activeValue: null,
      ruleForm: null,
      formData: null,
      title: '新增组织',
      rules: {
        orgPid: [{
          required: true,
          message: '请选择上级组织'
        }],
        schoolType: [{
          required: true,
          message: '请选择单位类型'
        }],
        educationStage: [{
          required: true,
          message: '请选择阶段'
        }],
        orgName: [{
          required: true,
          message: "请输入组织名称",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '组织名称不能带有特殊符号'
        }],
        loginName: [{
          required: true,
          message: "请输入登录账号",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '登录账号不能带有特殊符号'
        }],
        province: [{
          required: true,
          message: "请填写所属区域",
          type: 'string',
          trigger: 'change'
        }],
        roleId: [{
          required: true,
          message: "请选择组织登录权限",
          type: 'number',
          trigger: 'change'
        }],
        schoolName: [{
          required: true,
          message: "请输入所属单位",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '所属单位不能带有特殊符号'
        }],
        schoolLoginName: [{
          required: true,
          message: "请输入所属单位",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '所属单位不能带有特殊符号'
        }]
      },
      confirm() {
        refData.ruleForm.validate(async valid => {
          if (valid) {
            const params = JSON.parse(JSON.stringify(refData.formData));
            // if(!params.school){
            //   params.school = {
            //     schoolName: '',
            //     schoolType: null,
            //     educationStage: null,
            //     roleId:params.schoolRoleIds,
            //   }
            // }
            const url = props.orgId ? 'updateOrganization' : 'addOrganization';
            // params.school.schoolName = refData.formData.hasOwnProperty('schoolName')?refData.formData.schoolName:refData.formData.orgName;
            // params.school.educationStage = refData.formData.hasOwnProperty('educationStage')?refData.formData.educationStage:1;
            // params.school.roleId = 3;
            // refData.formData.hasOwnProperty('schoolName') && delete params.schoolName;
            // refData.formData.hasOwnProperty('educationStage') && delete params.educationStage;
            // refData.formData.hasOwnProperty('schoolType') && delete params.schoolType;
            // refData.formData.hasOwnProperty('schoolLoginName') && delete params.schoolLoginName;
            // refData.formData.hasOwnProperty('schoolRoleIds') && delete params.schoolRoleIds;
            const {
              msg,
              code
            } = await API[url](params);
            code == 0 && (emit("update:visible", false), Emitter.emit('updateOrg'), Emitter.emit('handleShow'));
            props.orgId && emit('handleSuccess');
            message[code == 0 ? 'success' : 'warning'](code == 0 ? `${props.orgId ? '修改' : '添加'}成功` : msg);
          }
        });
      },
      async getData(orgId) {
        const {
          data,
          code,
          msg
        } = await API.getByIdOrganization({
          id: orgId
        });
        if (code == 0) {
          refData.searchValue = data.parentName;
          refData.formData = data;
          refData.selectAreaValue = [data.province, data.city, data.district];
          getRole();
        }
      },
      init() {
        refData.searchValue = '';
        refData.selectAreaValue = [];
        refData.formData = {
          id: null,
          name: '',
          parentId: null,
          province: '',
          city: '',
          district: '',
          sort: 1,
          status: 0,
          remark: '',
          roleId: 1,
          loginName: '',
          isCreateSchool: 0
        };
      }
    });
    // 初始化表单
    refData.init();
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit("update:visible", val);
      }
    });
    watch(() => props.visible, val => {
      if (val) {
        refData.init();
        if (props.orgId !== null) {
          refData.title = '修改组织';
          nextTick(() => {
            refData.getData(props.orgId);
            refData.ruleForm.clearValidate();
          });
        } else {
          nextTick(() => {
            refData.formData.parentId = props.activeOrg.orgPid;
            refData.searchValue = props.activeOrg.orgName;
            refData.activeValue = props.activeOrg.activeValue;
            getRole();
            refData.ruleForm.clearValidate();
          });
          refData.title = '添加组织';
        }
      }
    }, {
      immediate: true
    });
    //获取当前账号下的全部角色 
    const getRole = async () => {
      const {
        data,
        code,
        msg
      } = await API.listRole({
        deptId: refData.formData.orgPid
      });
      if (code === 0) {
        const arr = data.list.map(item => ({
          value: item.id,
          label: item.name,
          ...item
        }));
        refData.roleType = arr;
      }
    };
    //选择组织
    const handleDoubleClick = data => {
      refData.formData.parentId = data.id;
    };
    //区域赋值
    const handleSelectArea = item => {
      refData.formData.province = item[0];
      refData.formData.city = item[1];
      refData.formData.district = item[2];
    };
    //新增角色
    const handleAddRole = () => {
      if (!refData.formData.parentId) {
        return message.warning('请先选择上级组织');
      }
      refData.resourceVisible = true;
      refData.roleOrgId = refData.formData.parentId;
      refData.resourceRef.isAdd = true;
    };
    const visibleChange = (type, roleType) => {
      if (!refData.formData.parentId) {
        message.warning("请先选择上级组织");
        return false;
      }
      refData.addType = type;
      refData.resourceVisible = true;
      refData.resourceRef.type = refData.roleType.filter(item => roleType === item.value);
      refData.roleOrgId = refData.formData.parentId;
      refData.resourceRef.isAdd = false;
    };
    const [onConfirm] = useDebounce(refData.confirm, 300);
    return {
      ...toRefs(refData),
      show,
      handleDoubleClick,
      handleSelectArea,
      visibleChange,
      onConfirm,
      handleAddRole
    };
  }
});